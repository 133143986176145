//设置拦截器
import axios from 'axios';
import { Message } from 'element-ui';
window.axiosCancel = []
let webBaseUrl = window.webConfig.webBaseUrl

axios.defaults.baseURL = webBaseUrl;
let msgCloseFlag = true;

axios.interceptors.response.use(res => {
    // console.log(res.data.message)
    if (res.data.status == "401") {
        if (msgCloseFlag) {
            msgCloseFlag = false;
            localStorage.clear()
            sessionStorage.clear()
            Message({
                type: "error",
                message: res.data.message,
                duration: 2000,
                onClose: (msg) => {
                    location.href = "/login";
                    msgCloseFlag = true;
                }
            });
        }
    } else {
        return res.data
    }
})
axios.interceptors.request.use(req => {
    req.headers = {
        'Content-Type': 'multipart/form-data'
    }
    let token = localStorage.getItem('Token')
    if (token) {
        req.headers.token = token
    }
    req.headers['device'] = "sss";

    req.cancelToken = new axios.CancelToken(cancel => {
        window.axiosCancel.push({
            cancel
        })
    })
    return req
})

export default {
    post(url, params) {
        return axios({
            url: url,
            method: 'post',
            data: params,
            timeout: 60000
        })
    },
    get(url, params) {
        return axios({
            url: url,
            method: 'get',
            params: params,
            timeout: 60000
        })
    },
    delete(url, params) {
        return axios({
            url: url,
            method: 'delete',
            params: params,
            timeout: 60000
        })
    }
}