<template>
    <div :class="{ 'show': fullTextShown, 'over': isOver }" @click="toggleText">
        <div ref="ellipsisDiv">{{ fullTextShown ? realText :
            shortText }}</div>
        <i class="el-icon-arrow-down"></i>
    </div>
</template>
  
<script>
export default {
    props: {
        text: {
            type: String, // 文本
            required: true
        },
        maxLength: {
            type: Number,
            default: () => this.textMaxLength
        }
    },
    data() {
        return {
            fullTextShown: false, // 显示完整文本
            isOver: false, // 是否溢出
            shortText: '', // 简短文本
            realText: this.text || '', // 完整文本
            textMaxLength: this.maxLength || 165 // 默认最大长度为165
        };
    },
    created() {
        this.checkOverflow();
    },
    watch: {
        text(newValue) {
            this.fullTextShown = false;
            this.realText = newValue;
            this.checkOverflow();
        },
        maxLength(newValue) {
            this.textMaxLength = newValue;
        }
    },
    methods: {
        // 检查文本是否溢出
        checkOverflow() {
            if (this.realText.length > this.textMaxLength) {
                this.isOver = true;
                this.shortText = this.realText.slice(0, this.textMaxLength) + '...';
            } else {
                this.isOver = false;
                this.shortText = this.realText;
            }
        },
        // 文本的展开与收起
        toggleText() {
            this.fullTextShown = !this.fullTextShown;
        }
    }
};
</script>
  
<style lang="scss" scoped>
i {
    display: none;
}

.over {
    position: relative;
    padding-right: 30px;
    cursor: pointer;

    i {
        cursor: pointer;
        font-size: 12px;
        position: absolute;
        top: 20px;
        right: 0;
        z-index: 9;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color:var(--bs-theme-color);
        border: 1px solid var(--bs-theme-color);
    }
    [class^=el-icon-]::before{
        margin: 0;
    }
}

.show {
    i {
        transform: rotate(180deg);
    }
}
</style>
  