//设置拦截器
import Vue from 'vue'
import { Message } from 'element-ui';
import interRequest from "@/apis/apis";

export const EventBus = new Vue();

//获取语种列表
export function getTransList(translateList, translateValue, showLoad) {
    showLoad = true;
    interRequest.getTransList().then(res => {

        if (res.status == '200') {
            for (const key in res.data) {
                translateList.push({ label: res.data[key], value: key });
            }
            translateValue = translateList[0].value
            // console.log(translateValue)

        } else {
            Message.error(res.message);
        }
        showLoad = false;
    });
}
export function getUploadDatasource(kusSource, showLoad) {
    showLoad = true;
    interRequest.getUploadDatasource().then(res => {
        if (res.status == '200') {

            for (const key in res.data.kus) {
                kusSource.push(
                    { label: res.data.kus[key], value: key }
                )
            }
        } else {
            Message.error(res.message)
        }
        showLoad = false

    }, err => {
        Message.error(window.webConfig.errorMsg); // 在请求失败时显示错误提示
        showLoad = false
    })
}


//权益信息，用户信息
export function getBenefits(showLoad) {
    showLoad = true;
    return new Promise((resolve, reject) => {
        interRequest.getBenefits().then((res) => {
            if (res.status == "200") {
                localStorage.setItem("userMsg", JSON.stringify(res.data));
                const userMsg = JSON.parse(localStorage.getItem("userMsg"));
                showLoad = false;
                resolve(userMsg);
            } else {
                showLoad = false;
                reject(res.message);
            }
        }).catch(err => {
            showLoad = false;
            reject(window.webConfig.errorMsg);
        });
    });
}