<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>

export default {

}
</script>

<style>
body {
  overflow: hidden;
  overflow-y: visible;
}

.el-icon-caret-right:before {
  content: "\e791" !important;
}

.ql-snow .ql-picker {
  width: 80px;
}

.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='行高']::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='行高']::before {
  content: '行高';
}

.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1']::before {
  content: '1';
}

.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1.25']::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1.25']::before {
  content: '1.25';
}

.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1.5']::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1.5']::before {
  content: '1.5';
}

.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='1.75']::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='1.75']::before {
  content: '1.75';
}

.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='2']::before {
  content: '2';
}

.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='3']::before {
  content: '3';
}

.ql-snow .ql-picker.ql-lineHeight .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item[data-value='4']::before {
  content: '4';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='22px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='22px']::before {
  content: '22px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='26px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='26px']::before {
  content: '26px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='28px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='28px']::before {
  content: '28px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='30px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='30px']::before {
  content: '30px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
  content: '32px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='34px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='34px']::before {
  content: '34px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='36px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
  content: '36px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='38px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='38px']::before {
  content: '38px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='40px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='40px']::before {
  content: '40px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='42px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='42px']::before {
  content: '42px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
  content: '48px';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='宋体']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='宋体']::before {
  content: '宋体';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='黑体']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='黑体']::before {
  content: '黑体';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='微软雅黑']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='微软雅黑']::before {
  content: '微软雅黑';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='楷体']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='楷体']::before {
  content: '楷体';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='仿宋']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='仿宋']::before {
  content: '仿宋';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Arial']::before {
  content: 'Arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='苹方']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='苹方']::before {
  content: '苹方';
}
</style>
