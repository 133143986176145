import request from "@/utils/request";
export default {
   /*----------------------------------
                                           用户登录
                                              ------------------------------------------------*/
   //用户登录
   login: (params) => request.post('/pro/login', params),
   //用户注册
   register: (params) => request.post('/pro/register', params),
   //获取菜单栏
   getMenu: (params) => request.get('/pro/index', params),
   //退出登录
   logout: (params) => request.get('/pro/logout', params),
   //修改账户密码
   editInfo: (params) => request.post('/pro/password', params),
   //获取aicc、黑格token
   switchPlatform: (params) => request.get('pro/switchPlatform', params),
   //获取权益配置列表
   getBenefits: (params) => request.get('/pro/upgrade/benefits', params),
   //获取邀请信息
   getInviteInfo: (params) => request.post('/pro/getInviteInfo', params),
   //获取支付
   getRechargeInfo: (params) => request.post('/pro/order/create', params),
   //查询支付状态
   getPayStatus: (params) => request.post('/pro/order/query', params),
   //用户列表
   getUserList: (params) => request.get('/pro/users', params),
   //变更员工状态
   modifyUserStatus: (params) => request.post('/pro/userStatus', params),
   //添加用户或者修改用户信息
   modifyUserInfo: (params) => request.post('/pro/saveUser', params),
   //修改公司名
   modifyCompanyName: (params) => request.post('/pro/company', params),
   //数据记录
   getDataLog: (params) => request.get('pro/upgrade/data/log', params),
   //分配用户电力值额度
   getElectric: (params) => request.get('/pro/company/electric', params),

   /*----------------------------------
                                           会话
                                              ------------------------------------------------*/
   // 获取所有应用
   getApps: (params) => request.get('/pro/app', params),
   //安装应用
   installApp: (params) => request.post('/pro/app/install', params),
   //获取所有菜单
   getAllMenu: (params) => request.get('/pro/menu', params),
   //获取当前应用的其他应用配置
   getCurrentAppInfo: (params) => request.get('/pro/app/user/other/config', params),
   //获取会话对应数据
   getTalkDate: (params) => request.get('/pro/app/user/other/config', params),
   // 获取好友列表
   getFriendList: (params) => request.get('pro/app/talk/friend', params),
   // 获取当前好友对话记录
   getTalkRecord: (params) => request.get('/pro/app/talk/news', params),
   // 获取历史消息
   getTalkHistoryList: (params) => request.get('/pro/app/talk/history', params),
   // 获取当前好友信息
   getCustomerinfo: (params) => request.get('/pro/app/talk/customer', params),
   //发送消息
   sendMessage: (params) => request.post('/pro/app/talk/send', params),
   //撤回消息
   recallMessage: (params) => request.post('/pro/app/talk/recallMsg', params),
   //获取配置标签
   // getConfigTag: (params) => request.get('/pro/customer/config', params),
   //保存配置 
   saveConfig: (params) => request.post('/pro/app/config', params),
   // 修改标题
   editTitle: (params) => request.post('/pro/app/title', params),
   // 删除/恢复会话
   removeApp: (params) => request.get('/pro/app/del', params),
   // 获取删除应用列表
   getDelList: (params) => request.get('/pro/app/del/list', params),
   // 人工干预
   personHandler: (params) => request.post('/pro/app/talk/personHandler', params),

   /*----------------------------------
                                           会话配置
                                              ------------------------------------------------*/

   /*--------------社媒---------------*/

   // 账号绑定列表
   socialshareList: (params) => request.get('/pro/socialshare', params),
   // 获取账户授权token
   getAuthorizationToken: (params) => request.get('/pro/socialshare/authorization/token', params),
   // 获取账户绑定平台
   getAuthorizationPlatform: (params) => request.get('/pro/socialshare/authorization/platform', params),
   // 获取发帖列表
   getSocialPostList: (params) => request.get('/pro/socialshare/article', params),
   // 创建发帖任务
   sendPostTask: (params) => request.post('/pro/socialshare/send/out', params),
   // 查询任务状态
   getSocialListStatus: (params) => request.post('/pro/socialshare/status/check', params),

   /*----------------------------------
                                     三方App
                                        ------------------------------------------------*/
   /*-----------Tiktok------------*/
   //获取douyin账户状态详情
   getTiktokAccount: (params) => request.get('/pro/douyin', params),
   //获取douyin授权二维码链接
   getTiktokCode: (params) => request.get('/pro/douyin/auth', params),

   /*-----------WA个人版------------*/
   //获取WA个人版状态详情
   getWAPersonAccount: (params) => request.get('/pro/whatsapp', params),
   //获取WA个人版授权二维码链接
   getWAPersonCode: (params) => request.get('/pro/whatsapp/auth', params),

   /*----------------------------------
                                        客户
                                           ------------------------------------------------*/
   /*-----------客户列表------------*/
   //获取客户列表 
   getCustomerList: (params) => request.get('/pro/customer', params),
   //指定操作员 
   getOperator: (params) => request.get('/pro/customer/config', params),
   //编辑客户信息
   modifyCustomerInfo: (params) => request.post('/pro/customer', params),
   //跟进记录列表
   getRecordsList: (params) => request.get('/pro/customer/follow', params),
   //新增跟进记录 
   addRecords: (params) => request.post('/pro/customer/follow', params),

   //客户会话管理
   getCustomerTalk: (params) => request.post('/pro/customer/talk', params),

   /*-----------ai标签------------*/
   //获取ai标签列表
   getAILabelList: (params) => request.get('/pro/customer/custom/ai/labels', params),
   //添加ai标签
   addAILabel: (params) => request.post('/pro/customer/custom/ai/labels/add', params),
   //编辑ai标签
   editAILabel: (params) => request.post('/pro/customer/custom/ai/labels/edit', params),
   //删除ai标签
   delAILabel: (params) => request.get('/pro/customer/custom/ai/labels/del', params),

   /*-----------自定义字段------------*/
   //获取字段列表
   getCustomFields: (params) => request.get('/pro/customer/custom/field', params),
   //获取字段列表配置
   getCustomFieldsConfig: (params) => request.get('/pro/customer/custom/field/config', params),
   //添加字段
   addCustomField: (params) => request.post('/pro/customer/custom/add', params),
   //编辑字段
   editCustomField: (params) => request.post('/pro/customer/custom/edit', params),
   //删除字段
   delCustomField: (params) => request.get('/pro/customer/custom/del', params),

   /*-----------拓展字段------------*/
   //获取拓展字段列表
   getExpandFields: (params) => request.get('/pro/customer/custom/expand/field', params),
   //选择拓展字段
   chooseExpandFields: (params) => request.post('/pro/customer/custom/expand/choose', params),
   //检查是否选择拓展字段
   checkExpandFieldsChoose: (params) => request.get('/pro/customer/custom/expand/choose/check', params),
   //添加拓展字段
   addExpandField: (params) => request.post('/pro/customer/custom/expand/add', params),
   //编辑拓展字段
   editExpandField: (params) => request.post('/pro/customer/custom/expand/edit', params),
   //删除拓展字段
   delExpandField: (params) => request.get('/pro/customer/custom/expand/del', params),
   //获取拓展字段唯一值
   getExpandFieldUuid: (params) => request.get('/pro/customer/custom/expand/field/uuid', params),
   //关闭开关
   switchExpandField: (params) => request.post('/pro/customer/custom/expand/edit/switch', params),
   // 编辑拓展字段值
   editExpandFieldVal: (params) => request.post('/pro/customer/custom/expand/edit/value', params),
   // 获取字段用户列表
   gettUserFieldList: (params) => request.get('/pro/customer/custom/user', params),
   // 编辑字段用户信息
   editUserFieldVal: (params) => request.post('/pro/customer/custom/user/edit', params),

   /*-----------标签管理------------*/
   //获取标签列表
   getCustomerLabel: (params) => request.get('/pro/customer/label', params),
   //修改/新增标签 
   addModifyLabel: (params) => request.post('/pro/customer/label', params),
   //启用/禁用 标签
   onOffLabel: (params) => request.get('/pro/customer/label/status', params),

   /*----------- AI用户画像------------*/
   //获取 AI用户画像列表
   getUserPortraitList: (params) => request.get('/pro/customer/ai/label', params),
   //启用/禁用  
   onOffStatus: (params) => request.get('/pro/customer/ai/label/status', params),
   //新增
   createUserPortrait: (params) => request.post('/pro/customer/ai/label/task', params),
   //任务详情
   getUserPortraitTaskDetail: (params) => request.get('/pro/customer/ai/label/detail', params),
   //删除任务
   delUserPortraitTask: (params) => request.get('/pro/customer/ai/label/delete', params),

   /*-----------草稿转成品图------------*/
   //草稿转成品图
   sketchToImage: (params) => request.post('/pro/sketch/image', params),

   /*----------------------------------
                                        创作
                                           ------------------------------------------------*/

   /*-----------独立站------------*/
   //获取配置信息
   getConfigInfo: (params) => request.get('/pro/article/config', params),
   //添加文案
   addArticle: (params) => request.post('/pro/article/add', params),
   //修改文案
   modifyArticle: (params) => request.post('/pro/article/edit', params),
   //获取文案
   getArticle: (params) => request.get('/pro/article/detail', params),
   //删除文案
   removeArticle: (params) => request.get('/pro/article/del', params),
   //获取列表
   getAITaskList: (params) => request.get('/pro/article', params),

   /*-----------电商平台------------*/
   //获取电商列表
   getCommerceList: (params) => request.get('/pro/e/commerce', params),
   //获取电商获取配置
   getCommerceConfigInfo: (params) => request.get('/pro/e/commerce/config', params),
   //AI生成
   aiReply: (params) => request.post('/pro/e/commerce/reply', params),
   //保存数据
   aiSave: (params) => request.post('/pro/e/commerce/save', params),
   //保存数据
   removeCommerceList: (params) => request.get('/pro/e/commerce/del', params),

   /*-----------大模型------------*/
   // gpt 消息列表
   getChatList: (params) => request.get('/pro/ai/chat', params),
   // 历史消息列表
   getHistoryList: (params) => request.get('/pro/gpt/history', params),
   // 发送消息
   sendMsg: (params) => request.post('/pro/gpt/send/msg', params),
   // 更新会话
   updateMsg: (params) => request.post('/pro/gpt/send/update', params),
   // gpt 历史消息删除
   removeMsg: (params) => request.get('/pro/gpt/history/del', params),
   // gpt 新建会话
   createMsg: (params) => request.post('/pro/gpt/send/create', params),
   // 机器人市场
   getRobotList: (params) => request.get('/pro/ai/robot/market', params),
   // 安装机器人 
   installRobot: (params) => request.post('/pro/ai/robot/install', params),
   // 卸载机器人 
   removeRobot: (params) => request.post('/pro/ai/robot/uninstall', params),

   /*---------------------------指令------------------------*/
   // 已有列表
   getpromptTask: (params) => request.get('/pro/prompt/task', params),
   // 新增指令
   createInstruction: (params) => request.post('/pro/prompt/task/save', params),
   // 流程条件
   getTaskFlow: (params) => request.get('/pro/prompt/task/flow', params),
   // 删除指令
   removeInstruction: (id) => request.delete(`/pro/prompt/task/${id}`),
   // 指令详情
   getInstructionDetails: (id) => request.get(`/pro/prompt/task/${id}`),

   // 保存指令流程
   savepromptTask: (params) => request.post('/pro/prompt/task/save/flows', params),


   // 同步微信、企业微信通讯录
   syncContact: (params) => request.get(('/pro/app/sync_contact'), params),



   /*----------------------------------
                                         训练
                                            ------------------------------------------------*/

   /*-----------数据分库------------*/
   //库列表
   getDatabaseList: (params) => request.get('/pro/ai/database', params),
   //新增库
   addDatabase: (params) => request.post('/pro/ai/database/create', params),
   //更新库
   updateDatabase: (params) => request.post('/pro/ai/database/update', params),
   //删除库
   removeDatabase: (params) => request.get('/pro/ai/database/del', params),
   //用户数据源
   getDatasource: (params) => request.get('/pro/ai/database/datasource', params),
   //获取详情
   getDatasourceDetail: (params) => request.get('/pro/ai/database/info', params),

   /*-----------录入文档------------*/
   //文件上传
   filesUpload: (params) => request.post('/pro/ai/doc/upload', params),
   //输入文本
   inputText: (params) => request.post('/pro/ai/doc/input_text', params),
   //网页抓取
   crawlingWeb: (params) => request.post('/pro/ai/doc/collect', params),
   //获取文档列表
   getDocList: (params) => request.get('/pro/ai/doc', params),
   //导入文件数据
   importUpload: (params) => request.post('/pro/ai/doc/import', params),
   //删除文件上传
   removeTextUpload: (params) => request.post('/pro/ai/doc/del', params),
   // 查询生成问答状态
   getDocImportStatus: (params) => request.post('/pro/ai/doc/import_status', params),

   /*-----------录入问答------------*/
   //录入问答
   saveQesAndAns: (params) => request.post('/pro/ai/text/save', params),
   //GPT文件上传
   filesUploadQA: (params) => request.post('/pro/tool/upload_gpt', params),
   //批量上传问答
   filesUploadQAs: (params) => request.post('/pro/ai/text/upload', params),
   //获取问答列表
   getQaAList: (params) => request.get('/pro/ai/text', params),


   /*-----------数据集成------------*/
   //获取数据集成列表
   getIntegrationTableList: (params) => request.get('/pro/gpt/integration', params),
   //获取数据源
   getIntegrationDatasource: (params) => request.get('/pro/gpt/integration/datasource', params),
   //新增
   addIntegration: (params) => request.post('/pro/gpt/integration/create', params),
   //删除
   removeIntegration: (params) => request.get('/pro/gpt/integration/del', params),
   //导入
   importIntegration: (params) => request.get('/pro/gpt/integration/detail/import', params),
   //导出
   exportIntegration: (params) => request.get('/pro/gpt/integration/detail/export', params),
   // 详情列表
   getDetailList: (params) => request.get('/pro/gpt/integration/detail', params),
   //删除详情数据
   removeDetail: (params) => request.get('/pro/gpt/integration/detail/del', params),
   //修改详情数据
   updateDetail: (params) => request.post('/pro/gpt/integration/detail/update', params),

   /*-----------数据上传------------*/
   //文案上传列表
   getUploadList: (params) => request.get('/pro/ai/upload', params),
   //删除文案上传列表
   removeUpload: (params) => request.get('/pro/ai/upload/del', params),
   //文案上传
   createUpload: (params) => request.post('/pro/ai/upload/create', params),
   //导入文件数据
   // importUpload: (params) => request.post('/pro/ai/upload/import', params),
   //获取数据源
   getUploadDatasource: (params) => request.get('/pro/ai/doc/datasource', params),
   //查询导入状态
   getImportStatus: (params) => request.get('/pro/ai/upload/import_status', params),
   //修改/新增数据文案
   createModifyText: (params) => request.post('/pro/ai/text/save', params),

   /*-----------数据文案------------*/
   //gpt 数据文案列表
   getTextList: (params) => request.get('/pro/ai/text', params),
   //私有库数据源
   getTextDatasource: (params) => request.get('/pro/ai/text/datasource', params),
   //删除数据文案
   removeText: (params) => request.post('/pro/ai/text/del', params),
   // //gpt 文案
   // removeDatabase: (params) => request.get('/pro/ai/text', params),
   // //gpt 文案上传列表
   // removeDatabase: (params) => request.get('/pro/ai/upload', params),
   /*-------------------搜索-----------------------------*/
   getSearchAi: (params) => request.get('/pro/search/ai', params),

   // 获取AI企业所有详情(扣算力值)
   getAiCompany: (params) => request.get('/pro/search/ai/company', params),
   // 获取AI企业详情
   getAiCompanyByDomain: (params) => request.get('/pro/search/ai/company/domain', params),
   // 获取AI企业洞察详情
   getAiCompanyDetail: (params) => request.get('/pro/search/ai/company/detail', params),
   // 获取AI企业洞察搜索数据
   getAiCompanySearchData: (params) => request.get('/pro/search/ai/company/search/data', params),

   /*-------------------工具-----------------------------*/
   // 验证邮箱有效性
   checkEmailValidity: (params) => request.post('/pro/tool/checkEmail', params),
   // 拆分邮箱
   mailVerify: (params) => request.post('/pro/tool/mailVerify', params),
   // 验证手机号码有效性
   checkPhoneValidity: (params) => request.post('/pro/tool/checkPhone', params),
   // 拆分手机号
   phoneVerify: (params) => request.post('/pro/tool/phoneVerify', params),
   // Tik Tok视频下载
   videoDownloadTT: (params) => request.post('/pro/tool/videoDownloadTT', params),
   // YTB 视频下载
   videoDownloadYTB: (params) => request.post('/pro/tool/videoDownloadYTB', params),
   // 文件上传
   fileUpload: (params) => request.post('/pro/tool/uploadFile', params),
   // 视频上传
   videoUpload: (params) => request.post('/pro/tool/uploadCirleVideo', params),

   /*----------------------------------
                                            绘图
                                               ------------------------------------------------*/
   /*-----------文生图------------*/
   //获取图片配置
   getDrawConfig: (params) => request.get('/pro/picture/config', params),
   //生成图片
   getAiPicture: (params) => request.post('/pro/picture', params),
   //获取生成图片的信息
   getAiPictureInfo: (params) => request.get('/pro/picture/info', params),

   /*-----------图片列表------------*/
   //获取图片列表
   getPictureList: (params) => request.get('/pro/picture', params),
   //删除图片列表
   removePictureList: (params) => request.get('/pro/picture/del', params),
   //获取图片信息
   getPictureInfo: (params) => request.get('/pro/picture/details', params),

   /*-----------智能抠图------------*/
   //获取背景图片信息
   getBacImgList: (params) => request.get('/pro/picture/background', params),
   //切割图片上传
   uploadFrontImg: (params) => request.post('/pro/matting/task', params),
   //获取抠图结果
   getMattingImg: (params) => request.get('/pro/matting/picture', params),
   //提交合成后图片信息
   UploadMattingImg: (params) => request.post('/pro/matting/build', params),
   //下载图片
   downloadMattingImg: (params) => request.get('/pro/picture/transfer', params),

   /*-----------服装模特------------*/
   //获取图片配置
   getModelConfig: (params) => request.get('/pro/model/clothes/config', params),
   //获取模特图片列表
   getModelImgList: (params) => request.get('/pro/model/clothes/model', params),
   //获取场景图片列表
   getSceneImgList: (params) => request.get('/pro/model/clothes/scene', params),
   //上传图片
   subImg: (params) => request.post('/pro/model/clothes/mask/image', params),
   //创建选区任务
   creatMission: (params) => request.post('/pro/model/clothes/selector/task', params),
   //移除背景
   removeBg: (params) => request.post('/pro/model/clothes/bg/remove', params),
   //查询选区结果
   getMask: (params) => request.get('/pro/model/clothes/selector', params),
   //设置蒙版图
   setMask: (params) => request.post('/pro/model/clothes/mask/setting', params),
   //创建作图任务
   sendAll: (params) => request.post('/pro/model/clothes/pain/task', params),
   //查询作图任务
   getResultask: (params) => request.get('/pro/model/clothes/pain', params),

   /*----------------------------------
                                           自动
                                              ------------------------------------------------*/
   /*------微信自动化-----*/
   /*微信添加好友任务*/
   //添加好友 - 任务列表
   getFriendTask: (params) => request.get('/pro/wechat/add_friend_task', params),
   //添加好友 - 数据源
   getFriendDatasource: (params) => request.get('/pro/wechat/add_friend_task/datasource', params),
   //添加好友 - 创建任务
   createFriendTask: (params) => request.post('/pro/wechat/add_friend_task/create', params),
   //添加好友 - 删除任务
   removeFriendTask: (params) => request.get('/pro/wechat/add_friend_task/delete', params),
   //添加好友 - 停用/启用
   changeStatus: (params) => request.get('/pro/wechat/add_friend_task/status', params),
   //添加好友 - 详情
   getFriendTaskDetails: (params) => request.get('/pro/wechat/add_friend_task/details', params),

   /*微信群发任务*/
   //群发任务 - 任务列表
   getGroupSendTask: (params) => request.get('/pro/wechat/group_send_task', params),
   //群发任务 - 数据源
   getGroupSendDatasource: (params) => request.get('/pro/wechat/group_send_task/datasource', params),
   //群发任务 - 创建任务
   createGroupSendTask: (params) => request.post('/pro/wechat/group_send_task/create', params),
   //群发任务 - 删除任务
   removeGroupSend: (params) => request.get('/pro/wechat/group_send_task/delete', params),
   //群发任务 - 停用/启用
   changeGroupSendStatus: (params) => request.get('/pro/wechat/group_send_task/status', params),
   //群发任务 - 详情
   getGroupSendDetails: (params) => request.get('/pro/wechat/group_send_task/details', params),

   //获取素材列表
   getMaterialList: (params) => request.get('/pro/material/library', params),
   //添加素材
   addMaterial: (params) => request.post('/pro/material/library/add', params),
   //编辑素材
   editMaterial: (params) => request.post('/pro/material/library/edit', params),
   //上传素材
   uploadMaterial: (params) => request.post('/pro/material/library/upload', params),
   //删除素材
   delMaterial: (params) => request.get('/pro/material/library/del', params),
   // 上传html
   uploadHtmlMaterial: (params) => request.post('/pro/material/library/upload/html', params),

   /*微信自动化任务*/
   //自动化任务 - 任务列表
   getAutoSendTask: (params) => request.get('/pro/wechat/auto_send_task', params),
   //自动化任务 - 数据源
   getAutoSendDatasource: (params) => request.get('/pro/wechat/auto_send_task/datasource', params),
   //自动化任务 - 创建任务
   createAutoSendTask: (params) => request.post('/pro/wechat/auto_send_task/create', params),
   //自动化任务 - 删除任务
   removeAutoSend: (params) => request.get('/pro/wechat/auto_send_task/delete', params),
   //自动化任务 - 停用/启用
   changeAutoSendStatus: (params) => request.get('/pro/wechat/auto_send_task/status', params),
   //自动化任务 - 详情
   getAutoSendDetails: (params) => request.get('/pro/wechat/auto_send_task/details', params),

   /*微信 朋友圈点赞*/
   //朋友圈点赞 - 任务列表
   getCoFTask: (params) => request.get('/pro/wechat/circle_like_task', params),
   //朋友圈点赞 - 数据源
   getCoFDatasource: (params) => request.get('/pro/wechat/circle_like_task/datasource', params),
   //朋友圈点赞 - 创建任务
   createCoFTask: (params) => request.post('/pro/wechat/circle_like_task/create', params),
   //朋友圈点赞 - 删除任务
   removeCoFTask: (params) => request.get('/pro/wechat/circle_like_task/delete', params),
   //朋友圈点赞 - 停用/启用
   changeCoFStatus: (params) => request.get('/pro/wechat/circle_like_task/status', params),
   //朋友圈点赞 - 详情
   getCoFTaskDetails: (params) => request.get('/pro/wechat/circle_like_task/details', params),

   /*微信 朋友圈评论*/
   //朋友圈评论 - 任务列表
   getCommentCoFTask: (params) => request.get('/pro/wechat/circle_comment_task', params),
   //朋友圈评论 - 数据源
   getCommentCoFDatasource: (params) => request.get('/pro/wechat/circle_comment_task/datasource', params),
   //朋友圈评论 - 创建任务
   createCommentCoFTask: (params) => request.post('/pro/wechat/circle_comment_task/create', params),
   //朋友圈评论 - 删除任务
   removeCommentCoFTask: (params) => request.get('/pro/wechat/circle_comment_task/delete', params),
   //朋友圈评论 - 停用/启用
   changeCommentCoFStatus: (params) => request.get('/pro/wechat/circle_comment_task/status', params),
   //朋友圈评论 - 详情
   getCommentCoFTaskDetails: (params) => request.get('/pro/wechat/circle_comment_task/details', params),

   /*微信 朋友圈营销*/
   //朋友圈营销 - 任务列表
   getMarketCoFTask: (params) => request.get('/pro/wechat/circle_post_task', params),
   //朋友圈营销 - 数据源
   getMarketCoFDatasource: (params) => request.get('/pro/wechat/circle_post_task/datasource', params),
   //朋友圈营销 - 创建任务
   createMarketCoFTask: (params) => request.post('/pro/wechat/circle_post_task/create', params),
   //朋友圈营销 - 删除任务
   removeMarketCoFTask: (params) => request.get('/pro/wechat/circle_post_task/delete', params),
   //朋友圈营销 - 停用/启用
   changeMarketCoFStatus: (params) => request.get('/pro/wechat/circle_post_task/status', params),
   //朋友圈营销 - 详情
   getMarketCoFTaskDetails: (params) => request.get('/pro/wechat/circle_post_task/details', params),
   //朋友圈营销 - AI生成文案
   getMarketCoFAiText: (params) => request.get('/pro/wechat/circle_post_task/ai_text', params),

   /*AI视频混剪*/
   // 获取ai模板列表
   getAiTemplate: (params) => request.get('/pro/video/get_template', params),
   //文件上传
   uploadFile: (params) => request.post('/pro/file/upload', params),
   // 获取文件列表
   getMouldLists: (params) => request.get('/pro/file/getfile', params),
   // 修改文件名
   EditFilename: (params) => request.post('/pro/file/upfile', params),
   //创建视频任务
   uploadVideoInfo: (params) => request.post('/pro/video/video_create_task', params),
   // 获取任务列表
   getVideoList: (params) => request.get('/pro/video/video_task_list', params),
   // 获取视频编辑信息
   editVideoInfo: (params) => request.get('/pro/video/video_task_info', params),
   //提交任务
   sendVideoTask: (params) => request.post('/pro/video/video_send_task', params),
   // 下载视频
   downloadVideo: (params) => request.get('/pro/downloadfile', params),
   // 获取音色
   getVoice: (params) => request.get('/pro/video/get_voice', params),


   /*----------------------------------
                                            工具
                                               ------------------------------------------------*/
   //实时汇率     
   getExchangeRate: (params) => request.post('/pro/tool/exchangeRate', params),
   //获取语种列表
   getTransList: (params) => request.get('/pro/trans', params),
   //翻译     
   getTranslate: (params) => request.post('/pro/translate', params),
   //去除AI痕迹
   removeAITraces: (params) => request.post('/pro/tool/removeAITraces', params),
   //验证邮箱有效性 
   checkEmail: (params) => request.post('/pro/tool/checkEmail', params),
   //验证手机号码有效性
   checkPhone: (params) => request.post('/pro/tool/checkPhone', params),
   //vat信息查询
   vatSelect: (params) => request.post('/pro/tool/vatSelect', params),
   //谷歌排名关键词拆分(目前前端自行拆分，没有用这个)
   googleVerify: (params) => request.post('/pro/tool/googleVerify', params),
   //谷歌关键词排名
   googleKeywordRank: (params) => request.post('/pro/tool/googleKeywordRank', params),
   //SEO网页测评
   seoSelect: (params) => request.post('/pro/tool/seoSelect', params),
   //网站测速
   pageSpeed: (params) => request.post('/pro/tool/pageSpeed', params),

   //采集谷歌新闻
   gatherGoogleNews: (params) => request.post('/pro/tool/getGoogleNews', params),

   //扩展关键词
   getKeywordExpansion: (params) => request.post('/pro/tool/keywordExpansion', params),



   /*----------------------------------
                                           数据
                                              ------------------------------------------------*/
   /*YouTube:*/
   //设置关注频道
   set_channel_YouTube: (params) => request.post('/pro/ytb/set_channel', params),
   //获取用户频道信息
   get_channel_YouTube: (params) => request.get('/pro/ytb/get_channel', params),
   //关注频道视频
   get_video_YouTube: (params) => request.get('/pro/ytb/get_video', params),
   // 获取监控频道列表
   getChannelList: (params) => request.get('/pro/ytb/list_channel', params),
   // 修改监控频道标题
   editChannelTitle: (params) => request.post('/pro/ytb/title', params),

   //获取关键词排行
   getRank: (params) => request.get('/pro/ytb/keyword/rank', params),
   //获取渠道统计
   getChannelStatistics: (params) => request.get('/pro/ytb/channel/statistics', params),


   //获取项目详情
   getproject: (params) => request.post('/b/get_project', params),

   //退出登录
   loginOut: (params) => request.post('/pro/logout', params),
   //手机短信登录
   loginSms: (params) => request.post('/pro/sms', params),
   //获取站内信列表
   getMailList: (params) => request.post('b/mail/', params),
   //查询信件详情
   getMailInfo: (params) => request.post('b/mail/info', params),
   //通过code获取token
   getToken: (params) => request.post('b/autologin', params),

   //aicc、黑格跳转后解token
   createAHToken: (params) => request.post('b/globalSo_v6_login', params),
   //获取微信登录二维码
   getQRcode: (params) => request.post('b/qrcode', params),
   //扫码登录
   QRcodeLogin: (params) => request.post('b/getWechatLoginInfo', params),
   //选择登陆项目
   chooseProject: (params) => request.post('/b/projectLogin', params),
   //解绑微信
   unbindQR: (params) => request.post('/b/unbindWechat', params),
   //获取左侧菜单模块
   getModule: (params) => request.get('/b/template/module', params),
   //获取左侧菜单模块类型
   getModuleType: (params) => request.get('/b/template/module/type', params),

   // 后台切入登录-获取token
   otherLogin: (params) => request.post('/pro/other/login', params),

}