
let routes = [

    // {
    //     //测试数据
    //     path: 'train_integration_detail',
    //     name: '数据集成-详情',
    //     meta: {
    //         isSel: 13,
    //     },
    //     component: () => import('@/views/train_integration_detail')
    // }, 
    {
        //应用-配置
        path: 'dialogue_config',
        name: '配置',
        meta: {
            isSel: 2,
        },
        component: () => import('@/views/dialogue_config')
    }, {
        //统计面版(临时)
        path: 'dialogue/page_statistics',
        name: '统计面版',
        meta: {
            isSel: 2,
        },
        component: () => import('@/views/dialogue/page_statistics')
    }, {
        //邮件列表(临时)
        path: 'dialogue/email_list',
        name: '邮件列表',
        meta: {
            isSel: 2,
        },
        component: () => import('@/views/dialogue/email_list')
    }, {
        //机器人-角色信息
        path: 'robot/train_role',
        name: '机器人-角色信息',
        meta: {
            isSel: 13,
        },
        component: () => import('@/views/robot/train_role')
    }, {
        //机器人-知识库
        path: 'robot/train_knowledge_base',
        name: '机器人-知识库',
        meta: {
            isSel: 13,
        },
        component: () => import('@/views/robot/train_knowledge_base')
    }, {
        //机器人-机器人指令任务
        path: 'robot/instruction',
        name: '机器人-机器人指令任务',
        meta: {
            isSel: 13,
        },
        component: () => import('@/views/robot/instruction')
    }, {
        //员工管理
        path: 'global/member_manage',
        name: '员工管理',
        meta: {
            isSel: 2,
        },
        component: () => import('@/views/global/member_manage')
    }, {
        //数据记录
        path: 'global/data_record',
        name: '数据记录',
        meta: {
            isSel: 2,
        },
        component: () => import('@/views/global/data_record')
    }, {
        //文生图结果
        path: 'draw/draw_text_result',
        name: '文生图结果',
        meta: {
            isSel: 3,
        },
        component: () => import('@/views/draw/draw_text_result')
    }, {
        //图生图结果
        path: 'draw/draw_img_result',
        name: '图生图结果',
        meta: {
            isSel: 3,
        },
        component: () => import('@/views/draw/draw_img_result')
    }, {
        //草绘成图
        path: 'sketch',
        name: '草绘成图',
        meta: {
            isSel: 28,
        },
        component: () => import('@/views/sketch/sketch.vue')
    }, {
        //添加素材
        path: 'automation/material',
        name: '添加素材',
        meta: {
            isSel: 77,
        },
        component: () => import('@/views/automation/material_library.vue')
    }, {
        //渠道统计
        path: 'data/statistics',
        name: '渠道统计',
        meta: {
            isSel: 75,
        },
        component: () => import('@/views/data/data_statistics.vue')
    }, {
        //大模型对话（安装市场）
        path: 'robot/create_ai_chat_market',
        name: '大模型对话-安装市场',
        meta: {
            isSel: 13,
        },
        component: () => import('@/views/robot/create_ai_chat_market')
    }, {
        //搜索结果(临时)
        path: 'data/search_result',
        name: '搜索结果',
        meta: {
            isSel: 75,
        },
        component: () => import('@/views/data/search_result')
    }
    , {
        //机器人-流程
        path: 'robot/flow',
        name: '机器人指令-流程',
        meta: {
            isSel: 13,
        },
        component: () => import('@/views/robot/flow')
    }, {
        //海外社媒-添加
        path: 'social/add_social_list',
        name: '海外社媒-添加',
        meta: {
            isSel: 2,
        },
        component: () => import('@/views/social/add_social_list')
    }, {
        //海外社媒-列表
        path: 'social/show_social_list',
        name: '海外社媒-列表',
        meta: {
            isSel: 2,
        },
        component: () => import('@/views/social/show_social_list')
    }

]

export default {
    saveUser(user) {
        localStorage.setItem("userMsg", JSON.stringify(user));
    },
    isLogin() {
        let loginUser = localStorage.getItem("userMsg");
        // console.log("userMsg:" + loginUser)
        if (loginUser) {
            return true;
        } else {
            return false;
        }

    },

    getRoute(router) {
        let res = JSON.parse(localStorage.getItem("menus_B"));
        // console.log(res)
        if (res) {
            for (let menu of res) {
                if (menu._child && menu._child.length > 0) {
                    for (let secondMenu of menu._child) {

                        let paths = "";
                        let routeItem = {}
                        let other_id = ""
                        if (secondMenu.other_id) {
                            other_id = secondMenu.other_id
                            if (menu.id == 2) {
                                paths = "dialogue_talk_all";
                            }
                            if (menu.id == 13) {
                                paths = "robot/create_ai_chat_market";
                            }
                        } else {
                            paths = secondMenu.route.replace(".", "/");
                        }


                        routeItem = {
                            path: paths,
                            name: secondMenu.name,
                            meta: {
                                isSel: menu.id,
                                other_id: other_id
                            },
                            component: () => import("@/views/" + paths)
                        }
                        router.options.routes[4].children.unshift(routeItem);

                    }
                }
            }
            routes.forEach(val => {
                router.options.routes[4].children.push(val)
            })
            //重新加载路由配置
            router.addRoute(router.options.routes[4]);

        }
        // console.log(router.options.routes)
    }
}