<!-- 浮动操作按钮 Floating Action Button -->
<template>
    <vue-draggable-resizable ref="dragPlug" :resizable="false" :x="draggableData.x" :y="draggableData.y" :w="draggableData.w" :h="draggableData.h"
        :parent="true" style="position: fixed; z-index:9999999" @dragstop="dragEndHandle" @dragging="draggingHandle">
        <div class="float-box">
            <!-- 有2个或2个以上按钮 -->
            <div class="float_btn_group" style="position: relative;" v-if="type != 1 && mobile">
                <div class="menu-popover" :class="{'show':showPopover}" :data-placement="dynamicPlacement">
                    <div class="menu-list">
                        <el-button v-if="type != 4" type="text" size="small" icon="el-icon-chat-line-square"
                            class="mobile-fold" @click.prevent="checkoutStatus('fold')"></el-button>
                        <el-button v-if="type == 2 && flag" type="text" size="small" icon="el-icon-setting"
                            class="mobile-fold" @click.prevent="checkoutStatus('foldEls')"></el-button>
                        <el-button v-if="type == 4" type="text" size="small" icon="el-icon-magic-stick"
                            class="mobile-fold" @click.prevent="checkoutStatus('fold')"></el-button>
                        <el-button type="text" size="small" class="mobile-fold float_kf_mini" :class="{ 'mini': mini }"
                            @click.stop="openCustomService()">
                            <img src="@/assets/images/customer-service/ewm.png" />
                        </el-button>
                    </div>
                    <div class="popper-arrow"></div>
                </div>
                <el-button class="menu" :class="{'is-disabled':isDragging}" @click.stop="showPopover = !showPopover"><i class="el-icon-menu"></i></el-button>
            </div>
            <!-- 只有1个按钮 -->
            <div class="float_kf" v-else :class="{ 'mini': mini }">
                <div class="float_kf_main">
                    <i class="el-icon-close toggle-size" @click.stop="mini = true;"></i>
                    <img src="@/assets/images/t-code.png" class="t-code-on" />
                </div>
                <div class="float_kf_mini" :class="{ 'mobile': mobile,'is-disabled':isDragging }"
                    @click.stop="openCustomService()">
                    <img v-if="!mobile" src="@/assets/images/customer-service/kf_float.png" />
                    <img v-else src="@/assets/images/customer-service/ewm.png" />
                </div>
            </div>
        </div>
    </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import { EventBus } from '@/utils/common.js';

export default {
    components: {
        VueDraggableResizable
    },
    data() {
        return {
            type: 1, //页面类型 1->其他页面；2->会话页面；3->训练页面；4->AI绘图页面
            mini: false, //客服悬浮按钮大小状态
            mobile: false, //移动端
            flag: false, //会话详情页
            windowWidth: window.innerWidth, //窗口宽度
            windowHeight: window.innerHeight, //窗口高度
            currentPath: '/', //当前路由
            dynamicPlacement: 'top', //popover弹出框出现位置
            showPopover: false, //显示悬浮按钮弹出框
            isDragging: false,//正在拖拽中
            historyX: 0, //记录x轴偏移量
            draggableData: { //拖拽组件数据
                x:0,
                y:0,
                w:35,
                h:35
            }
        };
    },
    watch: {
        '$route': {
            immediate: true,
            deep: true,
            handler(val) {
                this.currentPath = this.$route.path;
                this.watchRoute(this.$route.path)
            }
        },
        'mini': {
            handler(val) {
                this.draggableW()
                this.draggableH()
                this.$nextTick(()=>{
                    this.$refs.dragPlug.width = this.draggableData.w;
                    this.$refs.dragPlug.height = this.draggableData.h;
                    // 非移动端
                    if(!this.mobile){
                        if(val){
                            // 大变小时，y轴向下偏移93；
                            this.draggableData.y += 93;
                            // x轴回到之前偏移量
                            this.draggableData.x = this.historyX;
                        }else{
                            // 小变大时，y轴向上移动93
                            this.draggableData.y -= 93
                            // 如果当前x轴的偏移量已经大于大弹框的宽度，那么x轴至少为一个单位的大弹框宽度
                            if(this.draggableData.x > (this.windowWidth - 235)){
                                // 记录x轴偏移量
                                this.historyX = this.draggableData.x;
                                this.draggableData.x = this.windowWidth - 235;
                            }
                        }
                    }
                    this.$refs.dragPlug.top = this.draggableData.y;
                    this.$refs.dragPlug.left = this.draggableData.x;
                })
            }
        }
    },
    created() {

    },
    mounted() {
        this.handleWindowResize()
        window.addEventListener('resize', this.handleWindowResize);

        this.draggableW()
        this.draggableH()
        this.draggableWidth()
        this.draggableHeight()
        // 限制pc端的悬浮按钮的拖拽范围
        this.$nextTick(()=>{
            if(!this.mobile){
                this.$refs.dragPlug.parentWidth = this.windowWidth - 135
            }
        })

        // 是否进入会话详情
        EventBus.$on('currentStatus', flag => {
            this.flag = flag;
        })
    },
    beforeDestroy() {
        this.mini = false;
        this.showPopover = false;
        this.isDragging = false;
        window.removeEventListener('resize', this.handleWindowResize);
        EventBus.$off('currentStatus')
    },
    methods: {
        // 拖拽结束时 ed 
        dragEndHandle(x, y){
            this.isDragging = false;
            this.draggableData.x = x;
            this.draggableData.y = y;
            this.historyX = x;
        },
        // 拖拽悬浮按钮时 ing
        draggingHandle(x, y){
            this.isDragging = true;
            if(this.mobile){
                if(y <= 113){
                    this.dynamicPlacement = 'bottom'
                }else{
                    this.dynamicPlacement = 'top'
                }
            }
        },
        // 打开客服二维码
        openCustomService() {
            if (this.mobile) {
                EventBus.$emit('toggleDialog')
            }else{
                this.mini = false;
            }
        },
        // 展开侧栏，which => 哪一个侧栏
        checkoutStatus(which) {
            EventBus.$emit('toggleStatus', which)
        },
        // 监听路由
        watchRoute(currentPath) {
            this.flag = false
            this.showPopover = true;
            if (currentPath.indexOf('dialogue_talk_all') > -1) {
                this.type = 2;
            } else if (currentPath.indexOf('/robot/train_role') > -1 || currentPath.indexOf('/robot/create_ai_chat') > -1) {
                this.type = 3;
            } else if (currentPath.indexOf('/draw/draw_text') > -1 || currentPath.indexOf('/draw/draw_img') > -1 || currentPath.indexOf('/draw/draw_matting') > -1) {
                this.type = 4;
            } else {
                this.type = 1;
            }
            this.$nextTick(()=>{
                this.draggableW()
                this.draggableH()
                this.draggableWidth()
                this.draggableHeight()
                this.historyX = this.draggableData.x;
                // console.log(this.draggableData.w,this.draggableData.h)
                this.$refs.dragPlug.width = this.draggableData.w;
                this.$refs.dragPlug.height = this.draggableData.h;
            })
        },
        // 监听浏览器窗口大小
        handleWindowResize() {
            this.windowWidth = window.innerWidth
            this.windowHeight = window.innerHeight
            this.draggableW()
            this.draggableH()
            this.draggableWidth()
            this.draggableHeight()
            this.historyX = this.draggableData.x;
            if (this.windowWidth <= 900) {
                this.mini = true;
                this.mobile = true;
                this.showPopover = true;
            } else {
                this.mini = false;
                this.mobile = false;
                this.$nextTick(()=>{
                    this.$refs.dragPlug.parentWidth = this.windowWidth - 135
                })
            }
        },
        // 组件的当前宽度
        draggableW() {
            if (this.windowWidth > 900) {
                this.draggableData.w = this.mini ? 35 : 100
            } else {
                this.draggableData.w = this.type == 1 ? 35 : 51
            }
        },
        // 组件的当前高度
        draggableH() {
            if (this.windowWidth > 900) {
                this.draggableData.h = this.mini ? 35 : 128
            } else {
                this.draggableData.h = this.type == 1 ? 35 : 51
            }
        },
        // 组件距离父容器的左侧的距离
        draggableWidth() {
            if (this.windowWidth > 900) {
                this.draggableData.x = 0
            } else {
                this.draggableData.x = this.type == 1 ? window.innerWidth - 45 : window.innerWidth - 61
            }
        },
        // 组件距离父容器的顶部的距离
        draggableHeight() {
            if (this.windowWidth > 900) {
                this.draggableData.y = this.mini ? window.innerHeight - 50 : window.innerHeight - 143
            } else {
                this.draggableData.y = this.type == 1 ? window.innerHeight - 185 : window.innerHeight - 201
            }
        }
    },
};
</script>

<style lang="scss">
.is-disabled {
  pointer-events: none;
}
.float_btn_group {
    position: relative;
    z-index: 99;
    line-height: 1;
    padding: 8px;

    .menu {
        color: #EBEEF5;
        width: 35px;
        height: 35px;
        padding: 0;
        border: none;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, .6);

        span {
            line-height: 1.1;
        }
    }

    .menu:focus,
    .menu:hover {
        color: #EBEEF5;
        background-color: rgba(0, 0, 0, .6);
    }

    [class^=el-icon-]::before {
        margin: 0;
    }
}

.menu-popover{
    position: absolute;
    width: 40px;
    padding: 12px 6px;
    border-radius: 4px;
    background-color: #333333;
    transition: all .3s linear;
    opacity: 0;
    transform: scaleY(0);
    .menu-list{
        display: flex;
        flex-direction: column;
        align-items: center;

        .el-button {
            color: #EBEEF5;
            border: none;
            margin: 0 0 6px;
            padding: 0;
        }

        [class^=el-icon-] {
            font-size: 22px;
        }

        [class^=el-icon-]::before {
            margin: 0;
        }

        .float_kf_mini {
            position: relative;
            width: 16px;
            height: 16px;
            margin: 0 auto 2px;
        }

        .float_kf_mini.mini {
            opacity: 1;
            transform: scale(1);
        }

    }
}

.menu-popover.show{
    opacity: 1;
    transform: scaleY(1);
}

.menu-popover .popper-arrow{
    position: absolute;
    left: calc(50% - 6px);
    width: 0;
    height: 0;
}

.menu-popover[data-placement^=top]{
    left: calc(50% - 20px);
    bottom: 100%;
    margin-bottom: 4px;
    transform-origin: center bottom;
}

.menu-popover[data-placement^=top] .popper-arrow{
    top: 100%;
    border-top: 6px solid #333333;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.menu-popover[data-placement^=bottom]{
    left: calc(50% - 20px);
    top: 100%;
    margin-top: 4px;
    transform-origin: center top;
}

.menu-popover[data-placement^=bottom] .popper-arrow{
    bottom: 100%;
    border-top: 6px solid transparent;
    border-bottom: 6px solid #333333;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.draggable.vdr {
    left: 130px;
    top: 0;
    text-align: center;
    border: none;
}

.float_kf {
    position: relative;
    width: 100px;
    height: 128px;
    z-index: 999;
    // transition: all .3s linear;
}

.float_kf_main {
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: left bottom;
    transition: all .3s linear;
}

.toggle-size {
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    color: var(--bs-theme-color);
    width: 15px;
    height: 15px;
    font-size: 10px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(77, 121, 246, .5);
}

.toggle-size:before {
    margin: 0;
}

.float_kf_mini {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scale(0);
    transform-origin: left bottom;
    transition: all .3s linear;
    cursor: pointer;
}

.float_kf.mini {
    width: 35px;
    height: 35px;

    .float_kf_mini {
        opacity: 1;
        transform: scale(1);
    }

    .float_kf_main {
        opacity: 0;
        transform: scale(0);
    }
}

@media only screen and (max-width:900px) {
    .draggable.vdr {
        left: 0;
    }

    .float_kf_mini {
        opacity: 1;
        transform: scale(1);
    }

    .float_kf_main {
        opacity: 0;
        transform: scale(0);
    }

    .float_kf {
        // right: 10px;
        // left: auto;
        // bottom: 150px;
        width: 35px;
        height: 35px;

        .float_kf_main,
        .float_kf_mini {
            right: 0;
            left: auto;
            transform-origin: right bottom;
        }

        .float_kf_mini {
            border-radius: 50%;
            background-color: rgba(0, 0, 0, .6);

            img {
                width: 15px;
                height: 15px;
            }
        }
    }
}
</style>