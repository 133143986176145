<template>
  <div class="login-body" v-loading="showLoading">
    <div class="login-bg">
      <img :class="currentBgImg == '1' ? 'active' : ''" src="@/assets/images/loginPage/bg01.jpg" />
      <img :class="currentBgImg == '2' ? 'active' : ''" src="@/assets/images/loginPage/bg02.jpg" />
    </div>
    <div class="login-main">
      <div class="login-content">
        <div class="wrap">
          <h1 class="login-title"><img src="@/assets/images/loginPage/login-chaoji3.png" /> </h1>
          <el-carousel trigger="click" height="550px" :interval="5000" style="padding-bottom: 85px;">
            <el-carousel-item>
              <h2 class="carousel-title">Al机器人应用市场</h2>
              <p class="carousel-text opacity-5">超迹AI集成了H5、独立网页以及众多第三方应用:例如:微信、小程序、微信公众号、企业微信、钉钉、抖音、WhatsApp、 Facebook、
                Messenger、Line、 LinkedIn、TikTok、Twitter等</p>
              <div class="carousel-img">
                <vue-seamless-scroll :data="seamlessData" :class-option="defaultOption" class="seamless-content">
                  <ul class="ul-scoll">
                    <li v-for="(item, index) in seamlessData" :key='index'>
                      <img src="@/assets/images/loginPage/img01.png" />
                    </li>
                  </ul>
                </vue-seamless-scroll>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <h2 class="carousel-title">AI机器人 数据市场</h2>
              <p class="carousel-text opacity-5">超迹AI数据市场，集成了众多专业的Prompt及私有数据集，企业可直接应用专业的数据机器人，高效获取优质的内容 </p>
              <div class="carousel-img">
                <img src="@/assets/images/loginPage/img03.png" />
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <h2 class="carousel-title">数据集成市场</h2>
              <p class="carousel-text opacity-5">超迹AI为企业快速集成数据信息 </p>
              <div class="carousel-img">
                <img src="@/assets/images/loginPage/img02.png" />
              </div>
            </el-carousel-item>


          </el-carousel>
        </div>
      </div>
      <div class="login-tab">
        <div class="middle">
          <!-- <div class="login-title">
            欢迎使用"SaaS+AMP"模式的外贸全球搜平台
            <span class="login-plane">
              <i></i>
            </span>
          </div> -->
          <div class="middle-box" style="">

            <div class="login" v-if="pageStatus==='login'">
              <el-form :model="loginForm" :rules="rules" ref="loginForm" class=" ">
                <el-form-item prop="mobile">
                  <el-input type="text" v-model="loginForm.mobile" autocomplete="on" placeholder="手机号"
                    @keyup.enter.native="loginSubmitForm"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input type="password" v-model="loginForm.password" placeholder="请输入密码或者验证码"
                    @keyup.enter.native="loginSubmitForm">
                    <template #suffix>
                      <el-button type="button" class="codebtn" @click="getCode(1, loginForm.mobile)" :disabled="useBtn">
                        发送验证码
                        <span v-show="useBtn">({{ seconds }})</span>
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item class="sub-btn">
                  <el-button class="loginbtn gradient hor equity-btn" @click="loginSubmitForm"
                    v-loading="loginLoading">登录</el-button>
                  <div class="tip">没有账号，点击 <el-button size="mini" @click="getPageStatus('register')">注册</el-button>
                  </div>
                </el-form-item>

              </el-form>

            </div>
            <el-dialog class="xxl modify-dialog com-text" title=" " :visible.sync="dialogUserAgreement"
              :close-on-click-modal="false">
              <h2>一、 用户协议</h2>
              <p>1.超迹AI软件的所有权和运营权归创贸集团所有。</p>
              <p>2.
                用户在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为注册用户。一旦注册成功，则用户与app.ai.cc之间自动形成协议关系，用户应当受本协议的约束。用户在使用特殊的服务或产品时，应当同意接受相关协议后方能使用。
              </p>
              <p>3. 本协议可由app.ai.cc随时更新，本站不承担通知义务。本站的通知、公告、声明或其它类似内容是本协议的一部分。</p>
              <h2>二、 用户注册</h2>
              <p>1. 注册系统用户名需遵循如下原则：</p>
              <p class="sec">1.1 严禁注册以党和国家领导人或其他名人的真实姓名、字号、艺名、笔名做为系统注册用户名及使用其照片作头像；</p>
              <p class="sec">1.2 严禁注册以国家机构或其他机构、站点名称做为系统注册用户名；</p>
              <p class="sec">1.3 严禁注册带有广告嫌疑的笔名做为系统注册用户名；</p>
              <p class="sec">1.4 严禁注册和其他网友之名相近相仿的名字并恶意混淆视听的笔名作为系统注册用户名；</p>
              <p class="sec">1.5 严禁注册不文明、不健康的笔名做为系统注册用户名；</p>
              <p class="sec">1.6 严禁注册易产生歧义、引起他人误解的笔名做为系统注册用户名；</p>
              <p>2. 对于违反上述注册系统用户名原则的用户，系统有随时取消不合格用户系统用户名的权利。</p>
              <p>3. 请填写真实有效信息。</p>
              <p>4. 注册用户发表的言论不得违反国家的法律法规以及系统的各项规定。如有触犯国家法律、法规的，一切后果自行负责。</p>
              <p>5. 注册用户应妥善保管自己的系统用户名和密码。</p>
              <h2>三、系统用户使用规则</h2>
              <p>1. 本系统用户人人平等。</p>
              <p>2. 本系统用户的个人隐私受到本系统保护，不接受任何个人或单位的查询，但以下情况除外：</p>
              <p>用户本人授权同意系统透露这些信息；相关的法律及程序要求系统提供用户的个人资料；法律法规规定的其他情况。</p>
              <p>3. 用户享有在系统发表言论的权利，若系统各版面中有特殊规定，或因受到处罚而关闭权限则例外。</p>
              <p>4. 用户的任何言论行为应遵守国家法律、法规和本系统各项规章制度。</p>
              <h2>四、言论规则</h2>
              <p>用户对其自行发表的内容负全部责任，所有不得在本系统任何页面发布、转载、传送含有下列内容之一的信息，否则本系统有权自行处理并不通知用户：</p>
              <p>不得利用本系统危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益，不得利用本系统制作和传播下列信息：</p>
              <p>1. 煽动抗拒、破坏宪法和法律、行政法规实施的。</p>
              <p>2. 煽动颠覆国家政权，推翻社会主义制度的。</p>
              <p>3. 煽动分裂国家、破坏国家统一的。</p>
              <p>4. 煽动民族仇恨、民族歧视，破坏民族团结的。</p>
              <p>5. 捏造或者歪曲事实，散布谣言，扰乱社会秩序的。</p>
              <p>6. 宣扬封建迷信、淫 秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的。</p>
              <p>7. 侮辱或者诽谤他人，侵害他人合法权益。</p>
              <p>8. 未经系统允许发布广告内容。</p>
              <p>9. 其他违反宪法和法律，违反行政法规的内容。</p>
              <p>如用户在使用网络服务时违反上述任何规定，本系统有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户张贴的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。</p>
              <h2>五、版权声明</h2>
              <p>1 .本站的文字、图片、音频、视频等版权均归创贸集团享有，未经本站许可，不得任意转载。</p>
              <p>2. 本站特有的标识、版面设计、编排方式等版权均属创贸集团享有，未经本站许可，不得任意复制或转载。</p>
              <p>3. 恶意转载本站内容的，本站保留将其诉诸法律的权利。</p>
              <h2>六、附则</h2>
              <p>1. 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
              <p>2. 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
              <p>3. 本协议解释权及修订权归创贸集团所有。</p>
            </el-dialog>
            <el-dialog class="xxl modify-dialog com-text" title="隐私政策" :visible.sync="dialogPrivacyPolicy"
              :close-on-click-modal="false">
              <p>
                本软件尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本软件会按照本隐私权政策的规定使用和披露您的个人信息。但本软件将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本软件不会将这些信息对外披露或向第三方提供。本软件会不时更新本隐私权政策。您在同意本软件服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本软件服务使用协议不可分割的一部分。
              </p>
              <h3>1.适用范围</h3>
              <p class="sec">a)在您使用本软件网络服务，本软件自动接收并记录的您的手机上的信息，包括但不限于您的健康数据、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</p>
              <h3>2.信息的使用</h3>
              <p class="sec">a)在获得您的数据之后，本软件会将其上传至服务器，以生成您的排行榜数据，以便您能够更好地使用服务。</p>
              <h3>3.信息披露</h3>
              <p class="sec">a)本软件不会将您的信息披露给不受信任的第三方。</p>
              <p class="sec">b)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
              <p class="sec">c)如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露；</p>
              <h3>4.信息存储和交换</h3>
              <p class="sec">本软件收集的有关您的信息和资料将保存在本软件及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本软件收集信息和资料所在地的境外并在境外被访问、存储和展示。
              </p>
              <h3>5.信息安全</h3>
              <p class="sec">
                a)在使用本软件网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，请您立即联络本软件客服，以便本软件采取相应措施。
              </p>
            </el-dialog>
            <div class="register" v-if="pageStatus === 'register'">
              <el-form :model="registerForm" :rules="rules" ref="registerForm" class=" ">
                <el-form-item prop="mobile">
                  <el-input type="text" v-model="registerForm.mobile" autocomplete="on" placeholder="手机号"
                    @keyup.enter.native="registerSubmitForm"></el-input>
                </el-form-item>
                <el-form-item prop="vcode">
                  <el-input type="text" v-model="registerForm.vcode" placeholder="请输入验证码"
                    @keyup.enter.native="registerSubmitForm">
                    <template #suffix>
                      <el-button type="button" class="codebtn" @click="getCode(2, registerForm.mobile)"
                        :disabled="useBtn">
                        发送验证码
                        <span v-show="useBtn">({{ seconds }})</span>
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input type="text" v-model="registerForm.password" placeholder="请输入密码"
                    @keyup.enter.native="registerSubmitForm" show-password>

                  </el-input>
                </el-form-item>
                <el-form-item prop="password_confirmation">
                  <el-input type="text" v-model="registerForm.password_confirmation" placeholder="请重复密码"
                    @keyup.enter.native="registerSubmitForm" show-password>

                  </el-input>
                </el-form-item>
                <el-form-item class="sub-btn">
                  <el-button class="loginbtn gradient hor equity-btn" @click="registerSubmitForm"
                    v-loading="loginLoading">注册</el-button>
                  <div class="tip">已有账号，点击 <el-button size="mini" @click="getPageStatus('login')">登录</el-button></div>
                </el-form-item>
              </el-form>
            </div>
            <div class="find-password" v-if="pageStatus === 'findPassword'">
              findPassword
            </div>
            <ul>


              <!-- <li @click="getPageStatus('findPassword')">找回密码</li> -->
            </ul>

          </div>
          <el-checkbox-group v-model="ruleText">
            <el-checkbox label="" name="type"><em class="ftip" style="color:rgba(255,255,255,1)">我已阅读并同意 <a target=""
                  href="javascript:" style="color:#FFDC00" @click="dialogUserAgreement=true">用户协议 </a>和<a target=""
                  style="color:#FFDC00" href="javascript:" @click="dialogPrivacyPolicy=true">
                  隐私政策</a></em></el-checkbox>
          </el-checkbox-group>
          <!-- <div class="login-footer">© 2023 深圳创贸集团 版权所有</div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
 
import interRequest from "@/apis/apis";
import { Message } from "element-ui";
import sessionTool from "@/utils/sessionTool";
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll
  },
  data() {

    return {
      dialogUserAgreement:false,
      dialogPrivacyPolicy:false,
      pageStatus:"login",
      seamlessData: [],/*会话应用滚动图片*/
      currentBgImg: "",//当前背景图
      showLoading: false, 
      ruleText:[],
      loginForm: {
        mobile: "",
        password: "",
        
      }, 
      registerForm: {
        mobile: "",
        vcode: "",
        password: "",
        password_confirmation: "",
        invite_code:null,
        
      },
      rules: {
        
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }, 
        ],
        vcode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        password_confirmation: [
          { required: true, message: '请重复密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.registerForm.password) {
                callback(new Error('两次输入密码不一致'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
      },
      useBtn: false,
      seconds: 60,
      timer: null,
      loginLoading: false, 
      tokenParam:null,
      idValue: null,
      haveAccess: false,
      firstChildRoute: null,
    };
  },  
  created() {  
    this.tiaozhuan()
    if(localStorage.getItem('invite_code')){
      this.registerForm.invite_code = localStorage.getItem('invite_code')
      this.getPageStatus('register')
    }else{
      this.getPageStatus('login') 
    }
    /*随机展示背景图片 */
    this.randomizecurrentBgImg();
    setInterval(() => {
      // this.randomizecurrentBgImg();
      this.currentBgImg = this.currentBgImg === '1' ? '2' : '1';
    }, 8000);
    
    if (sessionStorage.getItem('selID')) {
      sessionStorage.removeItem('selID')
    }
    // if (localStorage.getItem('seconds') > 0) {
    //   this.seconds = localStorage.getItem('seconds')
    //   this.useBtn = true
    //   this.getSeconds()
    // }
  },
  mounted() {
    feather.replace()
    
    
    
  },
  methods: {
    tiaozhuan(){
      var currentUrl = window.location.href;

      // 使用正则表达式提取参数值
      var token = currentUrl.match(/param=([^&]*)/); 

      // 如果参数存在，则获取参数值
      if (token) {
        this.tokenParam = token[1];
        // console.log("Token value: " + this.tokenParam); 
        this.otherLogin()
      }

       
    },
    otherLogin(){
      this.loginLoading = true;
      interRequest.otherLogin({param: this.tokenParam }).then(res => {
       
        if (res.status == '200') {
          localStorage.setItem("Token", res.data.token); 
          this.loadData(res.message)
          localStorage.setItem("activePage", "安装应用");

        } else {
          Message.error(res.message)
          
        }
        this.loginLoading = false;
      }, err => {
        Message.error(window.webConfig.errorMsg); // 在请求失败时显示错误提示
        this.loginLoading = false;
        

      })
    },
    getPageStatus(val){
      this.pageStatus=val
      this.useBtn=false
      this.seconds= 60
      this.timer= null
    },
    /*切换背景图片*/
    randomizecurrentBgImg() {
      const randomIndex = Math.floor(Math.random() * 2) + 1;
      this.currentBgImg = randomIndex;

    },
    loadData(msg) {
      this.loginLoading = true;
      let requestArr = [this.getUserMenu(), this.getBenefits()];
      Promise.all(requestArr).then((results) => { 
        if (results[0].status == "200" && results[1].status == "200") { 
          // this.$router.replace("/robot/create_ai_chat"); // "/dialogue/apps"
          const urlParams = new URLSearchParams(window.location.search);
          let redirectUrl = urlParams.get('redirect_url');
          // console.log(redirectUrl)
          redirectUrl ? this.$router.replace(redirectUrl) : this.haveAccess ? this.$router.replace("/dialogue/apps") : this.$router.replace("/"+this.firstChildRoute);
          // Message.error("获取用户信息成功，请稍后！");
          Message.success(msg)
        } else {
          Message.error("获取用户信息失败，请重新登陆！") 
        }
        this.loginLoading = false;
      }).catch(err => {
        Message.error(window.webConfig.errorMsg);
        this.loginLoading = false;
      });
    }, 
    getUserMenu() {
      //获取菜单信息
      return interRequest.getMenu({}).then((res) => {
        if (res.status == "200") {
          localStorage.setItem('menus_B', JSON.stringify(res.data))//正式菜单
          sessionTool.getRoute(this.$router)
          res.data.forEach(element => {
            element._child.forEach(element_c=>{
              if (element_c.id == 12){
                sessionStorage.setItem('selID', JSON.stringify(element))
                localStorage.setItem("activePage", element_c.name);
                this.haveAccess = true;
              }
            })
          });  
          this.firstChildRoute = res.data[0]._child[0].route.split('.').join('/');
          
          // this.$router.push("/dialogue/apps"); 
        } else {
          // Message.error(res.message);
          // this.$router.push("/login"); 
        }
        return res; // 返回res作为Promise.resolve的参数
      })
    },
    // 获取权益信息
    getBenefits(param) {
      return interRequest.getBenefits(param).then((res) => {
        if (res.status == "200") {
          localStorage.setItem("userMsg", JSON.stringify(res.data));
          
        } else {
          // Message.error(res.message);
        }
        return res; // 返回res作为Promise.resolve的参数
      })
    },
    //倒计时
    getSeconds() {
      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          // localStorage.setItem('seconds', this.seconds)
          this.seconds--;
        } else {
          clearInterval(this.timer)
          // localStorage.setItem('seconds', 0)
          this.useBtn = false
          this.seconds = 60
        }
      }, 1000);
    },
   
    //获取验证码
    getCode(type, mobile) {
      if (mobile) {
        this.useBtn = true
        this.getSeconds()
        localStorage.setItem('seconds', this.seconds)
        interRequest.loginSms({ mobile: mobile , type : type }).then(res => {
          console.log(res.status)
          if (res.status == '200') {
            Message.success(res.message) 
            
          } else {
            Message.error(res.message)
            this.useBtn = false
            clearInterval(this.timer) 
            this.useBtn = false
          }
        }, err => {
          Message.error(window.webConfig.errorMsg); // 在请求失败时显示错误提示
          this.useBtn = false
          clearInterval(this.timer) 
          this.useBtn = false
          
        })
      } else {
        Message.error('请输入登录手机号')
      }
    },
   
    //登录
    loginMain(params){
      this.loginLoading = true
      interRequest.login(params).then((res) => {
        if (res.status == '200') {
          // Message.success(res.message)
          clearInterval(this.timer)
          // localStorage.setItem('seconds', 0)

          localStorage.setItem("Token", res.data.token);
          // sessionTool.saveUser(res.data)//正式用户信息
          
          
           localStorage.removeItem('invite_code'); 
          
          // localStorage.setItem('menus_B', JSON.stringify(this.$globalJson.menu))//测试菜单
          // this.getBenefits()
          this.loadData(res.message)
          localStorage.setItem("activePage", "安装应用");
          
          
 
        } else {
          Message.error(res.message);
          this.loginLoading = false
        }
      }, err => {
        Message.error(window.webConfig.errorMsg); // 在请求失败时显示错误提示
        this.loginLoading = false
      });

    },
    loginSubmitForm() {
      this.$refs.loginForm.validate(valid => {
        if (valid) { 
          if(this.ruleText.length>0){
            this.loginMain(this.loginForm)
          }else{
            Message.error("请阅读并同意用户协议和隐私政策")
          }
           
        } else { 
          return false;
        }
      });
      
    },
    //注册
    registerSubmitForm() {
      
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          if(this.ruleText.length>0){
            this.loginLoading = true
            interRequest.register(this.registerForm).then((res) => {
            if (res.status == '200') {
              Message.success(res.message)
              this.loginForm.mobile = this.registerForm.mobile
              this.loginForm.password = this.registerForm.password
              this.loginMain(this.loginForm) 
              localStorage.removeItem('invite_code');
              this.registerForm.invite_code=null
            } else {
              Message.error(res.message);
              this.loginLoading = false
            }
          }, err => {
            Message.error(window.webConfig.errorMsg); // 在请求失败时显示错误提示
            this.loginLoading = false
          });
           }else{
            Message.error("请阅读并同意用户协议和隐私政策")
          }
        } else {
          return false;
        }
      });

    },
    
  
  
  },
  computed: {
    defaultOption() {
      return {
        step: 0.5 // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        // direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }

  },
  mounted() {
    for (let i = 0; i < 15; i++) {
      let j = {
        url: ' ',
      }
      this.seamlessData.push(j)
    }
  }
};
</script>
<style lang="scss"> 
.login-content .el-carousel__arrow {
  display: none;
}

.login-content .el-carousel__indicators {
  width: 100%;
  height: 60px;
  bottom: 0;
}

.login-content .el-carousel__indicator--horizontal .el-carousel__button {
  color: #fff;
  width: 85px;
  font-size: 15px;
  height: 5px;
  border-radius: 3px;
  text-align: left;

}

.login-content .el-carousel__indicator--horizontal .el-carousel__button:after {
  position: relative;
  display: block;
  margin-top: 20px;
}

.login-content .el-carousel__indicator--horizontal:first-of-type .el-carousel__button:after {
  content: "会话应用";
}

.login-content .el-carousel__indicator--horizontal:nth-of-type(2) .el-carousel__button:after {
  content: "数据市场";
}

.login-content .el-carousel__indicator--horizontal:nth-of-type(3) .el-carousel__button:after {
  content: "数据集成";
}
.login-tab{
  .el-input__inner{
        height: 45px;
  }
  .sub-btn{
    .el-form-item__content{
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
       
      .el-button{
        margin: 0;
        border:none
      }
      .gradient:before,
      .gradient:hover:after {
          opacity: 0;
          visibility: hidden;
      }

      .gradient:hover:before,
      .gradient:after {
          opacity: 1;
          visibility: visible;
      }
      .tip{
        color:#fff
      }
    }
    .el-form-item__content:before,.el-form-item__content:after{
      display:none
    }
    
  }
}
.com-text{
  .el-dialog__header{
    padding:10px 20px;
  }
  .el-dialog__body{
    h2{
      font-size:18px;
    }
    p{
      padding:10px 0
    }
    p.sec{
      padding:10px 
    }
  }
}
 
</style>
<style src="@/assets/styles/loginPage/loginPage.min.css" scoped></style>
